<template>
  <div>
    <div class="text-center" v-if="loading">
      <span class="spinner-border mr-2" role="status" aria-hidden="true"></span>
      <div><span class="ml-2">Loading...</span></div>
    </div>
    <div class="text-center" v-else-if="questions.length === 0">
      <div class="my-4">
        <i style="font-size: 48px" class="text-warning material-icons-outlined"
          >warning_amber</i
        >
      </div>
      <p><i>There are no questions in this group.</i></p>
      <div class="mt-4 d-flex justify-content-center">
        <button
          @click="GET_QUESTION_BY_GROUP()"
          class="d-flex btn btn-warning btn-sm"
        >
          <i class="mr-2 text-warning material-icons-outlined">refresh</i>
          Refresh
        </button>
      </div>
    </div>
    <div v-else>
      <div
        :id="`question-${question.id}`"
        class="my-5"
        v-for="(question, i) in questions"
        :key="i"
      >
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="text-primary d-flex">
            <i class="mr-2 text-primary material-icons-outlined"
              >arrow_forward</i
            >
            {{ question.description }}
          </h5>
          <div>
            <span
              v-if="question.isRequired === 1"
              class="m-2 badge badge-danger"
              >* required</span
            >
            <span
              :class="`m-2 badge badge-${
                SET_QUESTION_TYPE(question.questionType).variant
              }`"
            >
              {{ SET_QUESTION_TYPE(question.questionType).text }}
            </span>
          </div>
        </div>
        <div class="mb-2"></div>
        <div
          v-if="question.options.length !== 0 && question.questionType === 3"
        >
          <Checkbox
            :value="option.id"
            v-for="(option, i) in question.options"
            :key="i"
            color="#5fd0a5"
            v-model="question.values"
            >{{ option.description }}</Checkbox
          >
          <Checkbox
            class="w-100 other"
            :value="question.otherText !== ''"
            color="#5fd0a5"
          >
            <div>
              <input
                class="w-100 p-2"
                maxlength="50"
                type="text"
                v-model="question.otherText"
                required
                aria-describedby="emailHelp"
                :placeholder="`Enter Other`"
              />
            </div>
          </Checkbox>
          <div
            v-if="
              question.isRequired === 1 &&
              question.values.length === 0 &&
              question.otherText === ''
            "
          >
            <small class="text-danger">
              * {{ question.description }} is required
            </small>
          </div>
        </div>
        <div v-else-if="question.questionType === 1">
          <div class="form-group">
            <input
              class="form-control"
              maxlength="50"
              v-model="question.values"
              required
              :class="{
                'border-danger':
                  question.isRequired === 1 && question.values === '',
              }"
              aria-describedby="emailHelp"
              :placeholder="`Enter ${question.description}`"
            />
          </div>
          <div v-if="question.isRequired === 1 && question.values === ''">
            <small class="text-danger">
              * {{ question.description }} is required
            </small>
          </div>
        </div>
        <div v-else-if="question.questionType === 2">
          <div class="form-group">
            <input
              class="form-control"
              maxlength="50"
              type="number"
              :class="{
                'border-danger':
                  question.isRequired === 1 && question.values === '',
              }"
              v-model="question.values"
              required
              aria-describedby="emailHelp"
              :placeholder="`Enter ${question.description}`"
            />
          </div>
          <div v-if="question.isRequired === 1 && question.values === ''">
            <small class="text-danger">
              * {{ question.description }} is required
            </small>
          </div>
        </div>
        <div v-else-if="question.questionType === 4">
          <v-select
            label="description"
            :searchable="false"
            class="style-chooser"
            :value="
              question.options.map((e) => e.id).indexOf(question.values) !== -1
                ? question.options[
                    question.options.map((e) => e.id).indexOf(question.values)
                  ].description
                : null
            "
            @option:selected="(e) => (question.values = e.id)"
            :clearable="false"
            :placeholder="`Search ${question.description}`"
            :options="question.options"
          ></v-select>

          <div v-if="question.isRequired === 1 && question.values === ''">
            <small class="text-danger">
              * {{ question.description }} is required
            </small>
          </div>
        </div>
        <div v-else-if="question.questionType === 5">
          <div class="form-group">
            <input
              class="form-control"
              maxlength="50"
              :class="{
                'border-danger text-danger':
                  question.isRequired === 1 && question.values === '',
              }"
              type="date"
              v-model="question.values"
              required
              aria-describedby="emailHelp"
              :placeholder="`Enter ${question.description}`"
            />
          </div>
          <div v-if="question.isRequired === 1 && question.values === ''">
            <small class="text-danger">
              * {{ question.description }} is required
            </small>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import Checkbox from "vue-material-checkbox";
import { mapGetters } from "vuex";
export default {
  components: { Checkbox },
  props: {
    group: {
      default: () => {},
    },
    getReportData: {
      default: () => {
        return {
          questions: {
            status: false,
            data: [],
          },
        };
      },
    },
    edit: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["getQuestionList"]),
  },
  data() {
    return {
      loading: true,
      questions: [],
      QuestionTypes: [
        { Id: 1, description: "Text" },
        { Id: 2, description: "Number" },
        { Id: 3, description: "Checkbox" },
        { Id: 4, description: "Single Select" },
        { Id: 5, description: "Date" },
      ],
    };
  },
  watch: {
    questions: {
      deep: true,
      handler(data) {
        this.$emit("questions", data);
      },
    },
  },
  methods: {
    SET_QUESTION_TYPE(type) {
      if (type === 1)
        return {
          variant: "light",
          text: "Text",
          element: "input",
          type: "text",
        };
      else if (type === 2)
        return {
          variant: "danger",
          text: "Number",
          element: "input",
          type: "number",
        };
      else if (type === 3)
        return {
          variant: "success",
          text: "Checkbox",
          element: "input",
          type: "checkbox",
        };
      else if (type === 4)
        return {
          variant: "info",
          text: "Select Box",
          element: "v-select",
          type: "",
        };
      else if (type === 5)
        return {
          variant: "warning",
          text: "Date",
          element: "input",
          type: "date",
        };
      else return { variant: "light", text: "Text" };
    },
    async GET_QUESTION_BY_GROUP() {
      console.log(this.getQuestionList);
      if (
        this.getQuestionList.id === this.group.groupId &&
        this.getQuestionList.list !== 0
      ) {
        this.loading = this.getQuestionList.isLoading;
        return this.SET_QUESTION(this.getQuestionList.list);
      }
      this.loading = true;
      const response = await this.$api.get(
        `Questions?groupId=${this.group.groupId}`
      );
      if (response.result === "OK" && response.message === "OK") {
        this.SET_QUESTION(response.data);
      } else this.questions = [];
      this.loading = false;
    },
    SET_QUESTION(data) {
      this.questions = data.map((d) => {
        let p = this.getReportData.questions.data
          .map((qd) => qd.questionId)
          .indexOf(d.id);
        if (!this.edit) p = -1;
        if (d.questionType === 3)
          return {
            ...d,
            otherText: "",
            updateReportId:
              p === -1 ? -1 : this.getReportData.questions.data[p].id,
            values:
              p === -1
                ? []
                : this.getReportData.questions.data[p].answers.map((a) => a.id),
          };
        else if (d.questionType === 4) {
          return {
            ...d,
            updateReportId:
              p === -1 ? -1 : this.getReportData.questions.data[p].id,
            values:
              p === -1
                ? ""
                : this.getReportData.questions.data[p].answers.length === 0
                ? ""
                : this.getReportData.questions.data[p].answers[0].id,
          };
        } else
          return {
            ...d,
            updateReportId:
              p === -1 ? -1 : this.getReportData.questions.data[p].id,
            values:
              p === -1
                ? ""
                : this.getReportData.questions.data[p].answers.length === 0
                ? ""
                : this.getReportData.questions.data[p].answers[0].value,
          };
      });
    },
  },
  mounted() {
    this.GET_QUESTION_BY_GROUP();
  },
};
</script>

<style>
svg {
  vertical-align: baseline !important;
}
.m-chckbox--container {
  margin: 5px 0;
}
.other .m-chckbox--label {
  width: 100%;
}
</style>