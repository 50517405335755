<template>
  <div class="my-5">
    <img src="/images/flayer.png" class="d-none" alt="watermark" />
    <div class="text-center" v-if="getQuestionGroupListIsLoading">
      <span class="spinner-border mr-2" role="status" aria-hidden="true"></span>
      <div><span class="ml-2">Loading...</span></div>
    </div>
    <div v-else>
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6">
          <div class="my-4">
            <router-link
              :to="`/${edit ? 'update' : 'create'}-report/${
                $route.params.reportId
              }/product`"
            >
              <button class="btn mr-2 d-flex btn-info btn-lg">
                <i class="mr-2 material-icons-outlined">arrow_back</i> Back to
                Step 2
              </button>
            </router-link>
          </div>
          <div id="alertbox" class="alert alert-danger" v-if="showAlertBox">
            <ul>
              <li v-for="(alert, i) in alertBox" :key="i">
                <div class="card-title">Group : {{ alert.parent }}</div>
                <p>{{ alert.message }}</p>
                <div class="mt-2 text-right">
                  <button
                    @click="SET_REDIRECT_TO_QUESTION(alert)"
                    class="btn btn-warning"
                  >
                    Redirect me to there
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        :id="group.groupId"
        class="row justify-content-center"
        v-for="(group, i) in questionGroupList"
        :key="i"
      >
        <div class="col-12 col-md-8 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div
                @click="activeQuestion = activeQuestion === i ? -1 : i"
                class="card-title d-flex justify-content-between"
              >
                <span>Group {{ i + 1 }} : {{ group.description }}</span>
                <i v-if="activeQuestion === i" class="material-icons-outlined"
                  >arrow_downward</i
                >
                <i v-else class="material-icons-outlined">arrow_forward</i>
              </div>
              <div v-show="activeQuestion === i">
                <question-list
                  :edit="edit"
                  :get-report-data="getReportData"
                  @questions="group.questions = $event"
                  :group="group"
                />
                <div
                  v-if="i !== questionGroupList.length - 1"
                  class="mt-4 d-flex justify-content-end"
                >
                  <button
                    @click="SET_NEXT_STEP(group, i)"
                    class="btn btn-primary d-flex"
                  >
                    Continue
                    <i class="ml-2 material-icons-outlined">arrow_downward</i>
                  </button>
                </div>
              </div>
              <div class="text-center" v-if="activeQuestion !== i">
                <p>
                  <i>Click for Show more...</i>
                </p>
                <div class="mt-4">
                  <button
                    @click="activeQuestion = i"
                    class="btn btn-info btn-xs"
                  >
                    Show More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6">
          <div class="form-group">
            <label for="">Arrival Time</label>
            <input
              class="form-control"
              maxlength="50"
              :class="{
                'border-danger text-danger': arrivalTime === '',
              }"
              type="datetime-local"
              v-model="arrivalTime"
              required
              aria-describedby="emailHelp"
              :placeholder="`Enter Arrival Time`"
            />
          </div>
          <div class="form-group">
            <label for="">Depart Time</label>
            <input
              class="form-control"
              maxlength="50"
              :class="{
                'border-danger text-danger': departTime === '',
              }"
              type="datetime-local"
              v-model="departTime"
              required
              aria-describedby="emailHelp"
              :placeholder="`Enter Depart Time`"
            />
          </div>
          <div class="form-group">
            <label for="">Comment</label>
            <textarea
              class="form-control"
              maxlength="500"
              rows="6"
              type="text"
              v-model="comment"
              required
              aria-describedby="emailHelp"
              :placeholder="`Enter Comment`"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!edit">
        <div class="col-12 col-md-8 col-lg-6">
          <div>
            <ReportImages
              :totalImageFileSize="totalImageFileSize"
              @remove:image="SET_REMOVE_IMAGE"
              :images="images"
            />
          </div>
          <div class="my-4 d-flex justify-content-center align-items-center">
            <a @click="$refs.fileInput.click()" href="javascript:void(0)"
              >+ Upload Image ({{ 10 - images.length }})</a
            >
            <input
              @change="SET_UPLOAD_IMAGE"
              accept="image/*"
              type="file"
              multiple="true"
              ref="fileInput"
              class="d-none"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6">
          <div class="mt-4 d-flex justify-content-end align-items-center">
            <button
              @click="confirmReportModal = true"
              :disabled="loading"
              :class="`btn btn-block btn-${
                edit ? 'warning' : 'primary'
              } btn-lg`"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
              {{ edit ? "Update" : "Complete" }} Report
            </button>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :okText="`Yes, ${edit ? 'Update' : 'Create'} a Report`"
      @ok="CONTROL_MIDDLEWARE"
      v-model="confirmReportModal"
      :hideFooter="alertOnModal.status"
      title="Do you want to publish a report?"
    >
      <div class="text-center" v-if="alertOnModal.status">
        <div>
          <i
            style="font-size: 72px"
            :class="{
              'text-success': alertOnModal.variant === 'success',
              'text-danger': alertOnModal.variant !== 'success',
            }"
            class="mr-2 material-icons-outlined"
            >{{ alertOnModal.variant === "success" ? "check" : "close" }}</i
          >
        </div>
        <p class="text-center mt-4">
          <strong>{{ alertOnModal.message }}</strong>
        </p>
      </div>
      <div v-else>
        <ul>
          <li>
            Company Name: <strong> {{ getReportData.company.name }}</strong>
          </li>
          <li>
            Brand Name: <strong>{{ getReportData.brand.name }}</strong>
          </li>
          <li>
            Model Number: <strong>{{ getReportData.product.model }}</strong>
          </li>
          <li>
            Serial Number:
            <strong>{{ getReportData.serialNo.serialNo }}</strong>
          </li>
        </ul>
        <div class="mt-4" v-if="!edit">
          <strong> {{ SET_UPLOAD_IMAGE_TEXT() }}</strong>
        </div>
        <div v-else class="mt-4">
          <strong>Do you want to continue?</strong>
        </div>
      </div>
    </Modal>
    <Modal
      okText="I got it"
      @ok="showAlertImageModal = false"
      v-model="showAlertImageModal"
      title="Warning!"
    >
      You must upload a maximum of {{ 10 - images.length }} image{{
        10 - images.length === 1 ? "" : "s"
      }}
    </Modal>
  </div>
</template>

<script>
import GetQuestionGroups from "./getQuestionGroups";
import QuestionList from "./questionList.vue";
import Modal from "../../../../ui/modal.vue";
import ReportImages from "./reportImages.vue";
export default {
  mixins: [GetQuestionGroups],
  components: {
    QuestionList,
    Modal,
    ReportImages,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showAlertImageModal: false,
      confirmReportModal: false,
      activeQuestion: 0,
      loading: false,
      arrivalTime: "",
      departTime: "",
      comment: "",
      alertBox: [],
      alertOnModal: {
        status: false,
        varinat: "success",
        message: "okay",
      },
      showAlertBox: false,
      images: [],
    };
  },
  computed: {
    getReportData() {
      return this.$store.state.createReport.createReport;
    },
    getUpdateReportId() {
      return this.$store.state.createReport.editReportId;
    },
    totalImageFileSize() {
      return this.images.reduce(function (acc, obj) {
        return acc + obj.size;
      }, 0);
    },
  },
  methods: {
    SET_REMOVE_IMAGE(index) {
      this.images.splice(index, 1);
    },
    SET_IMAGE_QUALITY(imgToCompress, resizingFactor, quality) {
      // resizing the image
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const originalWidth = imgToCompress.width;
      const originalHeight = imgToCompress.height;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      context.drawImage(
        imgToCompress,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      );
      const flayer = new Image();
      // context.textAlign = 'center'
      flayer.src = "/images/flayer.png";
      // flayer.style = 'opacity: 0.4;filter:brightness(100)'
      context.drawImage(
        flayer,
        canvasWidth - canvasWidth / 4 - canvasWidth / 2, //canvasWidth / 2 - flayer.width / 2,
        canvasHeight - canvasHeight / 5 - canvasWidth / 2, //canvasWidth / 2 - flayer.width / 2,
        canvasWidth / 2,
        canvasWidth / 2
      );
      // reducing the quality of the image
      return new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            console.log(blob, canvas);
            if (blob) {
              // showing the compressed image
              // resizedImage.src =
              const newFile = new File([blob], "image.png", {
                type: "image/png",
                lastModified: Date.now(),
              });
              resolve({
                blob,
                result: URL.createObjectURL(blob),
                file: newFile,
              });
            }
          },
          "image/jpeg",
          quality
        );
      });
    },
    async SET_UPLOAD_IMAGE(e) {
      this.showAlertImageModal = false;
      const images = e.target.files;
      if (this.images.length + images.length > 10)
        return (this.showAlertImageModal = true);
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const response = await this.$eralp.createImage(image);
        const img = new Image();
        img.src = response.uri;
        setTimeout(async () => {
          let x = 1;
          if (img.width > 600) x = 600 / img.width;
          const { result, file } = await this.SET_IMAGE_QUALITY(img, x, 0.5);
          this.images.push({
            ...response,
            file,
            uri: result,
            size: file.size,
            name: image.name,
          });
        }, 150);
      }
    },
    SET_UPLOAD_IMAGE_TEXT() {
      if (this.images.length === 0)
        return "You didn't upload a image. Do you want to continue?";
      else
        return `You have uploaded ${this.images.length} image${
          this.images.length === 1 ? "" : "s"
        }. Do you want to continue?`;
    },
    SET_REDIRECT_TO_QUESTION(row) {
      this.activeQuestion = row.index;
      setTimeout(() => {
        document.getElementById(`question-${row.questionId}`).scrollIntoView();
      }, 250);
    },
    SET_NEXT_STEP(group, index) {
      this.activeQuestion = index + 1;
      document.getElementById(group.groupId).scrollIntoView();
    },
    CONTROL_GROUPS() {
      let invalid = false;
      this.questionGroupList.forEach((group, index) => {
        const data = group.questions.filter((q) => q.isRequired === 1);
        data.forEach((required) => {
          if (
            (required.questionType === 1 ||
              required.questionType === 5 ||
              required.questionType === 4 ||
              required.questionType === 2) &&
            required.values === ""
          ) {
            invalid = true;
            this.alertBox.push({
              index,
              questionId: required.id,
              message: `${required.description} is required`,
              parent: group.description,
            });
          } else if (
            required.questionType === 3 &&
            required.values.length === 0 &&
            required.otherText === ""
          ) {
            invalid = true;
            this.alertBox.push({
              index,
              questionId: required.id,
              message: `${required.description} is required`,
              parent: group.description,
            });
          }
        });
      });
      if (this.alertBox.length !== 0) this.showAlertBox = true;
      if (this.departTime === "" || this.arrivalTime === "") invalid = true;
      return invalid;
    },
    CONTROL_MIDDLEWARE(e, loading) {
      if (this.edit) this.SEND_TO_UPDATE_REPORT(e, loading);
      else this.SEND_TO_REPORT(e, loading);
    },
    async SEND_TO_REPORT(e, loading, isCompleted = 1) {
      this.alertBox = [];
      const answers = [];
      const images = this.images.map((i) => i.file);
      this.showAlertBox = false;
      if (this.CONTROL_GROUPS()) {
        window.scrollTo(0, 0);
        this.confirmReportModal = false;
        return;
      }
      loading();
      this.questionGroupList.forEach((group) => {
        Array.prototype.push.apply(
          answers,
          group.questions.map((d) => {
            console.log(d, "question");
            if (d.questionType === 3)
              return {
                questionId: d.id,
                answer: d.values.join(","),
                otherText: d.otherText,
              };
            else
              return {
                questionId: d.id,
                answer: d.values,
                otherText: "",
              };
          })
        );
      });
      const fd = new FormData();
      fd.append("ReportId", this.$route.params.reportId);
      fd.append("CompanyProductId", this.getReportData.serialNo.id);
      fd.append("IsCompleted", isCompleted);
      fd.append("arrivalTime", this.arrivalTime);
      fd.append("comment", this.comment);
      fd.append("departTime", this.departTime);
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        fd.append("Images", image);
      }
      if (images.length === 0) fd.append("Images", "");
      fd.append(
        "Answers",
        JSON.stringify(
          answers.filter((a) => a.answer !== "" || a.otherText !== "")
        )
      );
      const response = await this.$api.post("CompanyReports", fd);
      if (response.result === "OK" && response.message === "OK") {
        this.alertOnModal = {
          message:
            "Report has been published with successfully. Redirecting to Report Detail....",
          status: true,
          variant: "success",
        };
        setTimeout(() => {
          localStorage.removeItem("report");
          localStorage.removeItem("edit");
          this.alertOnModal.status = false;
          this.confirmReportModal = false;
          this.$router.push(`/report-detail/${response.data}`);
        }, 2500);
      } else
        this.alertOnModal = {
          message:
            "Something went wrong. Please all data should check then try again.",
          status: true,
          variant: "danger",
        };
      setTimeout(() => {
        this.alertOnModal.status = false;
        this.confirmReportModal = false;
      }, 3500);
      loading(false);
    },
    async SEND_TO_UPDATE_REPORT(e, loading, isCompleted = 1) {
      if (this.getUpdateReportId === -1) return this.$router.push("/sign-in");
      this.alertBox = [];
      this.showAlertBox = false;
      if (this.CONTROL_GROUPS()) {
        window.scrollTo(0, 0);
        this.confirmReportModal = false;
        return;
      }
      loading();
      let newAnswers = [];
      let answers = [];
      this.questionGroupList.forEach((group) => {
        Array.prototype.push.apply(
          newAnswers,
          group.questions.map((d) => {
            if (d.updateReportId === -1) {
              if (d.questionType === 3)
                return {
                  questionId: d.id,
                  answer: d.values.join(","),
                };
              else
                return {
                  questionId: d.id,
                  answer: String(d.values),
                };
            } else {
              return {
                questionId: -1,
                answer: "",
              };
            }
          })
        );
        Array.prototype.push.apply(
          answers,
          group.questions.map((d) => {
            if (d.updateReportId !== -1) {
              if (d.questionType === 3)
                return {
                  updateReportId: d.updateReportId,
                  answer: d.values.join(","),
                };
              else
                return {
                  updateReportId: d.updateReportId,
                  answer: String(d.values),
                };
            } else {
              return {
                updateReportId: -1,
                answer: "",
              };
            }
          })
        );
      });
      const response = await this.$api.put(
        `CompanyReports/${this.getUpdateReportId}`,
        {
          arrivalTime: this.arrivalTime,
          departTime: this.departTime,
          companyProductId: this.getReportData.serialNo.id,
          isCompleted,
          comment: this.comment,
          isActive: 1,
          newAnswers: newAnswers.filter((n) => n.answer !== ""),
          removedAnswers: answers
            .filter((n) => n.updateReportId !== -1 && n.answer === "")
            .map((m) => m.updateReportId),
          updatedAnswers: answers
            .filter((n) => n.updateReportId !== -1 && n.answer !== "")
            .map((m) => {
              return {
                id: m.updateReportId,
                answer: m.answer,
              };
            }),
        }
      );
      if (response.result === "OK" && response.message === "OK") {
        this.alertOnModal = {
          message:
            "Report has been published with successfully. Redirecting to Report Detail....",
          status: true,
          variant: "success",
        };
        setTimeout(() => {
          this.alertOnModal.status = false;
          this.confirmReportModal = false;
          this.$router.push(`/report-detail/${this.getUpdateReportId}`);
        }, 2500);
      } else
        this.alertOnModal = {
          message:
            "Something went wrong. Please all data should check then try again.",
          status: true,
          variant: "danger",
        };
      loading(false);
    },
  },
  mounted() {
    if (this.getReportData.time.arrivalTime !== "" && this.edit)
      this.arrivalTime = this.getReportData.time.arrivalTime;
    if (this.getReportData.time.departTime !== "" && this.edit)
      this.departTime = this.getReportData.time.departTime;
    if (this.getReportData.comment !== "" && this.edit)
      this.comment = this.getReportData.comment;
  },
};
</script>

<style>
</style>